/*------------------------------------------------------------------


[Table of contents]

1. Body
2. Header
3. Footer
4. Blog
5. Portfolio
6. Section
7. Home Banner
8. About Us
9. Services
10. Skill
11. Testimonials
12. Contact Us

-------------------------------------------------------------------*/
 @import url(../plugin/bootstrap/css/bootstrap.min.css);
@import url(../plugin/themify-icons/themify-icons.css);
@import url(../plugin/owl-carousel/css/owl.carousel.min.css);
@import url(../plugin/magnific/magnific-popup.css);
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&amp;display=swap");
html {
  overflow-x: hidden;
} 
.main-btn {
    background-color: #222;
    color: white;
    display: inline-block;
    margin: auto 5px;
    padding: 5px;
    border: 2px solid #222;
    font-weight: 800;
  }


body {
  color: #5e6472;
  font-family: "Nunito", monospac;
  line-height: 1.7;
  font-size: 16px;
  font-weight: 400;
  overflow-x: hidden;
}

.font-alt {
  font-family: "Nunito", serif;
}

img {
  max-width: 100%;
}

* {
  outline: none !important;
}

a {
  color: #dc965a;
}
span:hover {
  color: #d58138;
  text-decoration: none;
}

mark {
  background-image: linear-gradient(#dc965a, #dc965a);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

/* ----------------------
*	Loading
---------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1E1E1E; /* Dark background for luxury feel */
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(45deg, #C7AE6A, #FFFFFF); /* Luxury gradient */
  box-shadow: 0 0 15px rgba(199, 174, 106, 0.6), 0 0 30px rgba(199, 174, 106, 0.4); /* Glowing effect */
  animation: rotate 2s linear infinite;
}

.load-circle::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #B17A50;
  border-radius: 50%;
}

.load-circle::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 5px solid #C7AE6A; /* Logo color for the inner rotating border */
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 1.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #0f0a0a;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  border-radius: 50%;
  margin: 0 5px;
}
.owl-dots .owl-dot.active {
  background: #0f0a0a;
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

/* Page title
--------------------------------*/
.page-title {
  padding: 160px 0 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1200px) {
  .page-title {
    padding-top: 130px;
  }
}
.page-title h1 {
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
}
@media (max-width: 991px) {
  .page-title h1 {
    font-size: 30px;
  }
}

/* Breadcrumbs
--------------------------------*/
.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border-radius: 0;
}
.breadcrumb li {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #fff;
}
.breadcrumb li + li {
  padding-left: 17px;
  margin-left: 17px;
}
.breadcrumb li + li:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
  opacity: 0.5;
  left: -2px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.breadcrumb li a {
  color: #fff;
}

/* Buttons
---------------------*/
.px-btn {
  padding: 0 25px;
  line-height: 46px;
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.px-btn.px-btn-theme {
  background: #dc965a;
  color: #fff;
}
.px-btn.px-btn-theme:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  top: 0;
  width: 0px;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  background: #0f0a0a;
  z-index: -1;
}
.px-btn.px-btn-theme:hover {
  color: #fff;
}
.px-btn.px-btn-theme:hover:after {
  width: 100%;
  left: auto;
  right: 0;
}
.px-btn.px-btn-white {
  background: #fff;
  border-color: #fff;
  color: #0f0a0a;
}
.px-btn.px-btn-white:hover {
  background: transparent;
  color: #fff;
}

.gray-bg {
  background: #fff5ec;
}

.theme-bg {
  background: #dc965a;
}

.dark-bg {
  background: #0f0a0a;
}

.color-dark {
  color: #0f0a0a;
}

.color-white {
  color: #fff;
}

.color-theme {
  color: #dc965a;
}

.navbar-toggler,
.toggler-menu {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 20px 0 0;
  border-radius: 0;
  padding: 0;
  margin-left: 15px;
  background: #dc965a;
  border: none;
}
.navbar-toggler span,
.toggler-menu span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 2px;
  margin: auto;
  box-shadow: 0px -8px 0 0px currentColor, 0px 8px 0 0px currentColor;
  background: #fff;
  color: #fff;
}
/* header start from here  */
@media (min-width: 992px) {
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .fixed-header .main-header {
    box-shadow: rgb(255, 255, 255) 0px 7px 20px -22px;
    background: #0f0a0a;
  }
  .main-header .navbar {
    padding: 0;
  }
  .main-header .navbar-brand {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
  }
  .fixed-header .main-header .navbar-brand {
    color: #0f0a0a;
  }
  .main-header .navbar-nav > li {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 20px;
  }
  .fixed-header .main-header .navbar-nav > li {
    border-right: 1px solid rgba(15, 10, 10, 0.1);
  }
  .main-header .navbar-nav > li:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  .fixed-header .main-header .navbar-nav > li:first-child {
    border-left: 1px solid rgba(15, 10, 10, 0.1);
  }
  .main-header .navbar-nav > li > a {
    padding: 0 10px;
    line-height: 70px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.05rem;
  }
  .main-header .navbar-nav > li > a.active {
    color: #fff;
  }
  .fixed-header .main-header .navbar-nav > li > a {
    color: #f0e9e9;
  }
  .fixed-header .main-header .navbar-nav > li > a.active {
    color: #df9859;
    /* color: #dc965a; */
  }
}
/* Mobile 
-----------------------------*/
@media (max-width: 991px) {
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 20px -22px;
  }
  .main-header .container {
    max-width: 100%;
    padding: 0;
  }
  .fixed-header .main-header {
    background: #000000;
  }
  .main-header .navbar {
    padding: 0;
  }
  .main-header .navbar-brand {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    margin: 15px 20px;
  }
  .fixed-header .main-header .navbar-brand {
    color: #16100e;

   
  }
  .logo {
    width: 100px; /* Set the desired width */
    height: auto; /* Maintain the aspect ratio */
}

  .main-header .navbar-nav {
    background: #fff;
  }
  .main-header .navbar-nav > li {
    border-top: 1px solid rgba(15, 10, 10, 0.1);
  }
  .main-header .navbar-nav > li > a {
    padding: 12px 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.05rem;
    color: #0a0f0c;
  }
  .main-header .navbar-nav > li > a.active {
    color: #dc965a;
  }
}

.header-transparent {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.header-colored {
  background-color: #000000; /* Your main color */
  transition: background-color 0.3s ease;
  
}

.nav-link {
  color: white; /* Default text color */
  
}

.nav-link .scrolled-text {
  color: rgb(255, 252, 252); 
}


.scrolled .logo {
  /* filter: brightness(0) invert(0);  */
}

/* Toggle button */
.navbar-toggler {
  border: none;
  color: white;
}

.navbar-toggler span {
  background-color: white;
}

.scrolled-toggle span {
  background-color: black; /* Toggle color when scrolled */
}

/*Footer
---------------------*/
.footer {
  background-color: #000000;
  color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding: 20px 0;
  font-size: 0.9rem;
}

.footer h5 {
  font-size: 1.1rem;
  color: #ffffff;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(220, 150, 90, 0.1);
  color: #dc965a;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  font-size: 1.1rem;
}

.social-icon:hover {
  background-color: #a3915a;
}

.footer p {
  margin-bottom: 0;
}

.designer-link {
  color: #C7AE6A;
  text-decoration: none;
  font-weight: bold;
}

.designer-link:hover {
  text-decoration: underline;
}


/* Blog 
-------------------*/
.blog-post .blog-img {
  position: relative;
  overflow: hidden;
}
.blog-post .blog-img img {
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog-post:hover .blog-img img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-post .data {
  position: absolute;
  top: 20px;
  left: 10px;
  background: #fff;
  padding: 8px 10px;
  line-height: 1;
  text-align: center;
  z-index: 1;
}
.blog-post .data span {
  width: 100%;
  display: inline-block;
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  margin: 0;
  color: #0f0a0a;
}
.blog-post .data small {
  font-size: 13px;
  text-transform: uppercase;
  color: #5e6472;
  margin-top: 7px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.blog-post .blog-info {
  padding: 20px;
  margin: -30px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: relative;
  background: #fff;
}
.blog-post .blog-info h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.blog-post .blog-info h6 a {
  color: #0f0a0a;
}
.blog-post .blog-info p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 0 12px;
}
.blog-post .blog-info .btn-bar a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1rem;
}

/* Blog List
-------------------*/
.blog-listing {
  padding-top: 30px;
  padding-bottom: 30px;
}
.blog-listing .pagination {
  margin-top: 30px;
}
.blog-listing .blog-post {
  border: 1px solid #eee;
  margin-bottom: 30px;
}
.blog-listing .blog-post .blog-info {
  margin: 0;
  box-shadow: none;
}

.pagination .page-item .page-link {
  border: none;
  box-shadow: none;
  color: #0f0a0a;
  margin: 0 5px;
  border-radius: 50px;
  padding: 0;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  background: #ddd;
}
.pagination .page-item .page-link:hover {
  background: #dc965a;
  border-color: #dc965a;
  color: #fff;
}
.pagination .page-item.disabled .page-link {
  opacity: 0.5;
}
.pagination .page-item.active .page-link {
  background: #dc965a;
  border-color: #dc965a;
  color: #fff;
}

/* Blog Sidebar
-------------------*/
.blog-aside {
  border-left: 1px solid #eee;
}
.blog-aside .widget {
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.blog-aside .widget:last-child {
  margin-bottom: 0;
}
.blog-aside .widget-title {
  border-bottom: 1px solid #eee;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 25px;
  padding-left: 25px;
}
.blog-aside .widget-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25px;
  width: 50px;
  height: 2px;
  background: #dc965a;
}
.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #0f0a0a;
  margin: 0;
}
.blog-aside .widget-body {
  padding-left: 25px;
}
.blog-aside .widget-author .media {
  margin-bottom: 15px;
}
.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}
.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #0f0a0a;
  font-size: 18px;
  margin: 0;
  padding-left: 20px;
}
.blog-aside .post-aside {
  margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #0f0a0a;
  font-weight: 500;
}
.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
  color: #5e6472;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}
.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
}
.blog-aside .latest-post-aside .lpa-title a {
  color: #0f0a0a;
  font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
  color: #5e6472;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #0f0a0a;
  background: #fff5ec;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-weight: 500;
}
.tag-cloud a:hover {
  background: #dc965a;
  color: #fff;
}

.blog-single {
  padding-top: 30px;
  padding-bottom: 30px;
}

.article {
  margin: 15px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #dc965a;
  border-bottom: 1px solid #dc965a;
}
.article .article-title h2 {
  color: #0f0a0a;
  font-weight: 700;
}
@media (max-width: 991px) {
  .article .article-title h2 {
    font-size: 24px;
  }
}
.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 500;
  color: #dc965a;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #0f0a0a;
  font-weight: 600;
  margin-bottom: 15px;
}
.article .article-content blockquote {
  width: 100%;
  padding: 30px;
  margin: 20px 0 30px;
  background: #fff5ec;
}
.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.article .article-content blockquote .blockquote-footer {
  color: #0f0a0a;
  font-size: 16px;
  padding-top: 20px;
}
.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600;
}
.article .tag-cloud {
  padding-top: 10px;
}

.article-comment {
  border: 1px solid #eee;
  overflow: hidden;
  background: #ffffff;
  padding: 30px;
  margin-bottom: 15px;
}
.article-comment h4 {
  color: #0f0a0a;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px;
}


/* portfolio  */
/* Ensure equal size images */
/* Ensure equal size images */
.portfolio-img {
  width: 100%;
  height: 300px; /* Set desired height */
  overflow: hidden; /* Hide any overflow from images */
}

.portfolio-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image is resized without distortion */
  /* Removed border-radius */
}

/* Rest of your existing styles */
.portfolio-filter-01 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.portfolio-filter-01 .filter li {
  cursor: pointer;
  margin: 0 15px;
  color: #0f0a0a;
  position: relative;
  padding: 5px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
}

@media (max-width: 767px) {
  .portfolio-filter-01 .filter li {
    margin: 0 8px;
    font-size: 15px;
  }
}

.portfolio-filter-01 .filter li:after {
  content: "";
  width: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #dc965a;
  position: absolute;
  transition: ease all 0.55s;
}

.portfolio-filter-01 .filter li.active:after {
  width: 100%;
}

.portfolio-box-01 {
  background: #fff;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  margin: 15px 0;
  position: relative;
}

.portfolio-box-01:hover:after {
  bottom: auto;
  top: 0;
  height: 100%;
}

.portfolio-box-01:hover .portfolio-info h5 {
  color: #fff;
}

.portfolio-box-01:hover .portfolio-icon {
  bottom: 8px;
  opacity: 1;
  z-index: 1;
}


/* Section
---------------------*/
.section {
  padding: 100px 0;
  position: relative;
}
@media (max-width: 991px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.after-left-section {
  position: relative;
  overflow: hidden;
}
.after-left-section:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 40%;
  background: #dc965a;
}
@media (max-width: 991px) {
  .after-left-section:after {
    display: none;
  }
}
.after-left-section .container {
  position: relative;
  z-index: 1;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h3 {
  font-size: 45px;
  color: #0f0a0a;
  font-weight: 800;
  position: relative;
  margin: 0 0 15px;
  padding: 0 0 15px;
}
@media (max-width: 767px) {
  .section-title h3 {
    font-size: 40px;
  }
}
.section-title h3:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background: #dc965a;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.section-title p {
  margin: 0;
  font-size: 17px;
}

.sm-title {
  margin-bottom: 30px;
}
.sm-title h4 {
  color: #0f0a0a;
  font-size: 24px;
  position: relative;
  padding: 0 0 15px;
  margin: 0;
  font-weight: 700;
}
.sm-title h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background: #dc965a;
}
.sm-title p {
  margin: 15px 0 0;
}

/* Home Banner
---------------------*/
.home-banner {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.5s ease-in-out;
}

/* Particle Effect */
.home-banner .particles-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  pointer-events: none;
  background: radial-gradient(transparent, rgba(255, 255, 255, 0.1));
  animation: particleMove 8s infinite ease-in-out;
}

@keyframes particleMove {
  0% { opacity: 0.2; transform: translateY(-10px); }
  50% { opacity: 0.5; transform: translateY(10px); }
  100% { opacity: 0.2; transform: translateY(-10px); }
}

/* Avatar Image */
.home-banner .hb-avatar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 50%;
  text-align: center;
}

.home-banner .hb-avatar img {
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
}

.home-banner .hb-avatar img:hover {
  transform: scale(1.1);
}

/* Top Style */
.home-banner .top-style {
  position: absolute;
  width: 350px;
  top: -80px;
  left: -30px;
  animation: slideDown 1.5s ease-in-out;
}

@media (max-width: 767px) {
  .home-banner .top-style {
    width: 200px;
    top: -100px;
    left: -50px;
  }
}

.home-banner .top-style svg {
  fill: #dc965a;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5));
}

/* Bottom Style */
.home-banner .bottom-style {
  position: absolute;
  width: 350px;
  bottom: -80px;
  right: -30px;
  animation: slideUp 1.5s ease-in-out;
}

@media (max-width: 767px) {
  .home-banner .bottom-style {
    width: 200px;
    bottom: -50px;
    right: -25px;
  }
}

.home-banner .bottom-style svg {
  fill: #dc965a;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5));
}

/* Animations */
@keyframes slideDown {
  from { opacity: 0; transform: translateY(-100px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(100px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Full Screen Banner */
.home-banner .full-screen {
  min-height: 100vh;
  padding-top: 150px;
  padding-bottom: 90px;
  /* background: linear-gradient(135deg, rgba(37, 36, 36, 0.5), rgba(48, 46, 46, 0.349)); */
}

/* Typography */
.home-banner .hb-typo h1 {
  font-size: 90px;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: 3px;
  text-shadow: 3px 3px 20px rgba(50, 152, 235, 0.315); /* Adds depth */
  animation: fadeInUp 2s ease-in-out;

  /* Gold gradient with shine */
  background-image: linear-gradient(310deg,
    #ec9a2f,  
    #ffd700,  
    #fdfdfc,  
    #497ceb   
  );
  
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Optional: Add shine movement for metallic look */
  animation: gradientMove 5s infinite alternate, shine 3s ease-in-out infinite;
  position: relative;
  /* z-index: 1; */
  overflow: hidden;
}

/* Animation for moving the gradient to create a flowing shine */
@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

/* Shine effect */
.home-banner .hb-typo h1::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75px;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.26);
  transform: skewX(-25deg);
  z-index: 2;
  animation: shineMove 2s ease-in-out infinite;
}

@keyframes shineMove {
  0% { left: -75px; }
  50% { left: 100%; }
  100% { left: 100%; }
}



/* Social Icons */
.home-banner .social-icons {
  position: absolute;
  right: 40px;
  bottom: 30px;
  display: flex;
  animation: fadeIn 2s ease-in-out;
}

@media (max-width: 767px) {
  .home-banner .social-icons {
    left: 40px;
    margin: auto;
  }
}

.home-banner .social-icons a {
  width: 45px;
  height: 45px;
  text-align: center;
  background: #fff5ec;
  border-radius: 50%;
  line-height: 45px;
  color: #0f0a0a;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.home-banner .social-icons a:hover {
  background: #0f0a0a;
  color: #fff;
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Scroll Down */
.home-banner .scroll-bottom {
  position: absolute;
  bottom: 30px;
  left: 50px;
  z-index: 2;
}

@media (max-width: 767px) {
  .home-banner .scroll-bottom {
    display: none;
  }
}

.home-banner .scroll-bottom a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.2rem;
  transition: color 0.3s;
}

.home-banner .scroll-bottom a:hover {
  color: #ffd700;
}

/* Fade in effect */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


/* About Us
---------------------*/
.about-section .container {
  position: relative;
  z-index: 1;
}
.about-section .img-box {
  position: relative;
  box-shadow: rgb(74, 123, 228) 0px 25px 50px -12px;
  background: #ffffff67;
  padding: 5px;
}
.about-section .img-box img {
  position: relative;
}
.about-section .typo-box {
  padding-left: 40px;
}
@media (max-width: 991px) {
  .about-section .typo-box {
    padding-top: 15px;
    padding-left: 0px;
  }
}
.about-section .typo-box h3 {
  font-size: 40px;
  font-weight: 700;
  color: #0f0a0a;
}
@media (max-width: 767px) {
  .about-section .typo-box h3 {
    font-size: 30px;
  }
}
.about-section .typo-box h5 {
  font-size: 18px;
  font-weight: 600;
  color: #0f0a0a;
  margin-bottom: 15px;
}
.about-section .typo-box .lead {
  font-weight: normal;
}
@media (max-width: 767px) {
  .about-section .typo-box .lead {
    font-size: 17px;
  }
}
.about-section .typo-box .btn-bar {
  padding-top: 12px;
}
.hero-section {
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.section {
  padding: 60px 0;
}

.certificate-section img, .exhibition-section img, .ceo-section img {
  border-radius: 10px;
}

.ceo-section img {
  max-width: 250px;
  border-radius: 50%;
}

.text-center {
  text-align: center;
}

.mb-5 {
  margin-bottom: 3rem;
}



/* not found start */
.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f7e11fa8, #E2DCC4);
  color: #333;
  text-align: center;
}

.content-wrapper {
  max-width: 600px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
}

.gem-icon {
  width: 100%;
  margin-bottom: 20px;
}

.display-1 {
  font-size: 5rem;
  color: #E2B043;
  font-weight: bold;
}

.btn-primary {
  background-color: #E2B043;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0c98f58c;
}

/* not found end */

/* Services
---------------------*/
.feature-box-01 {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  padding: 15% 12%;
  position: relative;
  z-index: 1;
}
.feature-box-01:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  background: #dc965a;
  opacity: 0.8;
  z-index: -1;
}
.feature-box-01 .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 50px;
  margin-bottom: 20px;
  color: #dc965a;
}
.feature-box-01 h5 {
  color: #0f0a0a;
  font-weight: 600;
}
.feature-box-01 p {
  margin: 0;
}
.feature-box-01:hover {
  color: rgba(255, 255, 255, 0.7);
}
.feature-box-01:hover .icon {
  color: #fff;
}
.feature-box-01:hover h5 {
  color: #fff;
}
.feature-box-01:hover:after {
  bottom: auto;
  top: 0;
  height: 100%;
}

/* Skill
---------------------*/
.skill-section .aducation-box {
  margin: 0;
  list-style: none;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.skill-section .aducation-box li + li {
  padding-top: 25px;
  margin-top: 20px;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}
.skill-section .aducation-box span {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 16px;
  color: #fff;
  padding: 4px 12px;
  border-radius: 30px;
  font-size: 12px;
  line-height: 1;
}
.skill-section .aducation-box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.skill-section .aducation-box p {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}
.skill-section .skill-box {
  min-height: calc(100% - 73px);
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.skill-lt:not(:first-child) {
  margin-top: 30px;
}
.skill-lt h6 {
  font-size: 16px;
  margin: 0 0 8px;
  color: #0f0a0a;
  line-height: 28px;
  font-weight: 600;
}
.skill-lt .skill-bar {
  position: relative;
  background: #0f0a0a;
}
.skill-lt .skill-bar .skill-bar-in {
  width: 80px;
  height: 10px;
  position: relative;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.skill-lt .skill-bar .skill-bar-in span {
  position: absolute;
  right: 0;
  bottom: 15px;
  background: #0f0a0a;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}
.dark-theme .skill-lt .skill-bar .skill-bar-in span {
  color: #0f0a0a;
  background: #fff;
}
.skill-lt .skill-bar .skill-bar-in span:after {
  content: "";
  position: absolute;
  bottom: -8px;
  right: 0;
  border-left: 7px solid transparent;
  border-top: 8px solid #0f0a0a;
}
.dark-theme .skill-lt .skill-bar .skill-bar-in span:after {
  border-top: 8px solid #fff;
}
.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px;
}
.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px;
}
.skill-lt.light .skill-bar {
  background: rgba(0, 0, 0, 0.5);
}

/* Testimonials
---------------------*/
/* .testmonial-box {
  margin: 20px 0 30px;
}
.testmonial-box .avatar {
  width: 30%;
  padding: 0 15px 15px;
  position: relative;
}
.testmonial-box .avatar:after {
  content: "";
  position: absolute;
  bottom: 5px;
  top: 15px;
  left: 5px;
  right: 30px;
  z-index: -1;
  border: 2px solid #dc965a;
}
.testmonial-box .testmonial-text {
  padding-left: 20px;
}
.testmonial-box .testmonial-text p {
  margin: 0;
}
.testmonial-box .testmonial-info {
  font-size: 12px;
  padding-top: 10px;
}
.testmonial-box .testmonial-info h6 {
  font-size: 15px;
  font-weight: 600;
  color: #0f0a0a;
  margin: 0 0 5px;
}
.testmonial-box .testmonial-info .rating-value {
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border-radius: 30px;
  padding: 0px 10px;
  box-shadow: 1px 1px 1px 1px rgba(15, 10, 10, 0.1);
  font-size: 9px;
}
.testmonial-box .testmonial-info .rating-value i {
  color: #ffc800;
  margin-right: 3px;
} */
/* .heading {
  position: relative;
} */
.testimonials {
  background: url("../img/bg7.jpg") center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 130px 0 150px;
  color: #ffffff;
  position: relative;
}

.testimonials:before {
  content: "";
  /* background: #f8f5f500; */
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
}

.testimonials .heading h2 {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff; /* Bright green */
}

.testimonials .heading h2 span {
  color: #ff0000; /* Red for emphasis */
}

.testimonials p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  color: #d1e5e7; /* Light gray-blue for readability */
  margin: 20px 0;
}

/* Image Styling */
/* Adjusted Image Size to Medium */
/* Image Styling */
/* Impressive Image Size */
/* Impressive Image Styling */
.testimonials .carousel-inner .carousel-item .team {
  width: 300px; /* Larger, more impressive size */
  height: 300px; /* Larger, more impressive size */
  border: 2px solid #ff9100c0; /* Bold red border */
  border-radius: 100%; /* Fully round image */
  padding: 10px;
  margin: 50px 0 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); /* Stronger shadow for emphasis */
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

/* .testimonials .carousel-inner .carousel-item .team:hover {
  transform: scale(1.1); /* Increase size on hover 
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.7); /* Deep shadow on hover 
  border-color: #0de931; /* Change border to a bright green on hover for a glowing effect 
} */

/* Optional: Adding animation for more dynamic appearance */
.testimonials .carousel-inner .carousel-item .team {
  animation: pulse 3s infinite;
}

.testimonials .carousel-inner .carousel-item h3 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700; /* Make client name stand out more */
}

.testimonials .carousel-inner .carousel-item h4 {
  font-size: 16px;
  font-weight: 400;
  color: #b8b8b8; /* Slightly lighter gray for job titles */
  margin-bottom: 20px;
}


/* Carousel Indicators */
.testimonials .carousel-indicators {
  bottom: -30px;
}

.testimonials .carousel-indicators li {
  background-color: #b8b7b7;
  border-radius: 30px;
  height: 4px;
  width: 40px;
}

.testimonials .carousel-indicators .active {
  background-color: #dc965a; /* Red for the active indicator */
}

/* Carousel Controls */
.testimonials .control span {
  cursor: pointer;
}

.testimonials .icon {
  height: 40px;
  width: 40px;
  background-size: 100%, 100%;
  border-radius: 50%;
  font-size: 30px;
  color: #ffffff;
 
}


/* Contact Us
---------------------*/
.contct-form {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  padding: 35px;
}
@media (max-width: 767px) {
  .contct-form {
    padding: 25px;
  }
}
.contct-form .form-group {
  margin-bottom: 30px;
  position: relative;
}
.contct-form .form-group .form-label {
  position: absolute;
  top: 10px;
  margin: 0;
  left: 2px;
  font-weight: 400;
  font-size: 14px;
  color: #0f0a0a;
  padding: 0 10px;
  pointer-events: none;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  background: #fff;
}
.contct-form .form-group .form-control {
  border-radius: 0;
  border: 1px solid rgba(15, 10, 10, 0.5);
  height: 40px;
  box-shadow: none !important;
  font-size: 15px;
  color: #0f0a0a;
}
.contct-form .form-group .form-control:focus {
  border-color: #dc965a !important;
}
.contct-form .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}
.contct-form .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}
.contct-form .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}
.contct-form .form-group .form-control::-moz-placeholder {
  color: transparent;
}
.contct-form .form-group .form-control::placeholder {
  color: transparent;
}
.contct-form .form-group .form-control:not(:placeholder-shown) ~ .form-label {
  top: -10px;
  font-size: 9px;
  border-radius: 30px;
  border: 1px solid rgba(15, 10, 10, 0.5);
  left: 10px;
}
.contct-form .form-group textarea.form-control {
  height: inherit;
}

.contact-info .media .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(220, 150, 90, 0.1);
  color: #dc965a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.contact-info .media .media-body {
  padding-left: 15px;
  color: #0f0a0a;
}
.contact-info .media + .media {
  margin-top: 20px;
}

/*# sourceMappingURL=style.css.map */
